import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './BlockUI.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NMaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5ccnRRRGlfUUdyWkU=');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); 
root.render(    
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
