import React, { Component, useEffect } from 'react';
import './App.css';
import CustomizedDialog from './DialogComponent.jsx';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import * as CustomJS from './Custom';
import BlockUi from '@availity/block-ui';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { random, isUndefined } from 'underscore';
import _ from 'underscore/underscore-min';

const CAPTCHA_SITE_KEY = "6Ld8SNoZAAAAAAMnGO_u70jU4o3yDzhWbA9nDVbv";
const reCaptchaRef = React.createRef();

export default class App extends Component {  
  constructor() {
    super(...arguments);           
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.loaderType = ['BallTriangle', 'Bars', 'Circles', 'Grid', 'Puff', 'Rings', 'TailSpin', 'ThreeDots', 'Oval', 'MutatingDots', 'RevolvingDot', 'Watch'];
    this.buttons = [{
      click: () => {
          this.dialogInstance.hide();
      },
      buttonModel: {
          content: 'CHIUDI',
          cssClass: 'e-primary',
          isPrimary: true
      }
    }];
    this.animationSettings = { effect: 'FadeZoom' };
  }  
  state = {
    blocking: false,
    showDialog: false        
  } 
  toggleBlocking() {        
    this.setState({blocking: !this.state.blocking});
  }   
  dialogClose() {
    window.location.reload();
  }
  dialogOpen() {     
  }  
  dialogContent() {
    return(<div className="control-section card-control-section basic_card_layout">
                <div className="e-card-resize-container">
                    <div className="card-layout">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <div tabIndex="0" className="e-card" id="main_card">
                                <div className="e-card-header">
                                    <div className="e-card-header-caption">                                            
                                        <div id='titoloInfoPerito' className="e-card-header-title" style={{ fontSize: '1.5vw', fontWeight: 'bold', color:'#133B95'}}></div>
                                    </div>
                                </div>
                                <div className="e-card-content">
                                    <div id='divFotoPerito' className='row'>
                                      <div className="avatar-block">   
                                        <div className="e-card">
                                              <div className="e-card-content">
                                                  <div className="e-lib e-avatar e-avatar-xlarge image"></div>                                                                                                   
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                    <div id='divNomePerito' className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div id='nomePerito' style={{ fontSize: '1.1vw', fontWeight: 'bold' }}></div>
                                        </div>
                                    </div>
                                    <div className='row'>&nbsp;</div>
                                    <div id='divEmailPerito' className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div id='indirizzoEmailPerito' style={{ fontSize: '1.1vw', fontWeight: 'bold' }}></div>
                                        </div>
                                    </div>
                                    <div className='row'>&nbsp;</div>
                                    <div id='divTelefPerito' className='row'>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div id='telefonoPerito' style={{ fontSize: '1.1vw', fontWeight: 'bold' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     </div>);
 } 

  render() {
    return (<React.Fragment>                             
              <BlockUi id='blockUICercaPerito' blocking={this.state.blocking} keepInView={true} loader={CustomJS.loaderSpinners[random(CustomJS.loaderSpinners.length - 1)]}>
                  <div id='sfondoCercaPerito'></div>                  
                  <div className="s01"> 
                    <ToastContainer containerId='toastContainer1' transition={Bounce} style={{ fontSize: '1.2vw', color: 'DarkRed', fontWeight: 'bolder', width: '35%' }}></ToastContainer>
                    <DialogComponent id="infoPeritoDialog" showCloseIcon={true} target='body' animationSettings={this.animationSettings} visible={this.state.showDialog} width='45%'
                                     ref={dialog => this.dialogInstance = dialog} header='Il tuo sinistro è stato trovato!!' buttons={this.buttons} height='auto' minHeight='9vw' isModal={true} 
                                     open={this.dialogOpen.bind(this)} close={this.dialogClose.bind(this)} content={this.dialogContent}></DialogComponent>
                    <form>
                      <fieldset>     
                        <div id='logoALLC'></div>                                                                 
                        <legend style={{color: '#FFFFF0', fontSize: '4.0vw', fontWeight: 'bolder'}}>Cerca il tuo perito</legend>                        
                      </fieldset>                      
                      <div className="inner-form">
                        <div className="input-field first-wrap">
                          <input id="NumeroSinistro" type="text" placeholder="Inserisci il numero del sinistro" />
                        </div>
                        <div className="input-field second-wrap">
                          <input id="CognomeAssicurato" type="text" placeholder="Inserisci il tuo cognome (o parte di esso)" />
                        </div>
                        <ReCAPTCHA ref={reCaptchaRef} size='compact' sitekey={CAPTCHA_SITE_KEY} onExpired={this.onExpireRecaptcha} onChange={this.onValidateRecaptcha}></ReCAPTCHA>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="input-field third-wrap">
                          <button id='btnCercaPerito' className="btn-search" type="button" onClick={this.caricaInfoPerito.bind(this)}>Cerca</button>
                        </div>    
                      </div>
                    </form> 
                  </div>
              </BlockUi>
          </React.Fragment>
    );
  }
  caricaInfoPerito() {
    if (CustomJS.isNullEmpty(document.getElementById('NumeroSinistro').value.trim())) {
      document.getElementById('NumeroSinistro').style.border = '3px solid';
      document.getElementById('NumeroSinistro').style.borderColor = 'red';
    }
    else
      document.getElementById('NumeroSinistro').style.border = 'none'; 
    if (document.getElementById('CognomeAssicurato').value.trim().length < 2) {
        document.getElementById('CognomeAssicurato').style.border = '3px solid';
        document.getElementById('CognomeAssicurato').style.borderColor = 'red';  
        toast.warn('Operazione non permessa. Inserire il numero del sinistro e almeno due caratteri del cognome!!', {
          //onClose: () => toast.dismiss(),   
          containerId:'toastContainer1',                 
          position: "top-right",
          autoClose: 7000,
          hideProgressBar: false,            
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          newestOnTop: true,
          rtl: false,
          pauseOnFocusLoss: true});      
    }    
    else
      document.getElementById('CognomeAssicurato').style.border = 'none';     
    if (!CustomJS.isNullEmpty(document.getElementById('NumeroSinistro').value.trim()) 
        && document.getElementById('CognomeAssicurato').value.trim().length > 1) {  
        this.toggleBlocking();           
        let numSinistro = document.getElementById('NumeroSinistro').value.replace(/[^a-zA-Z0-9]/g, "").trim();
        let cognomeAssicurato = document.getElementById('CognomeAssicurato').value.trim();  
        const jsonObject = { 
          username: CustomJS.UserNameBetacomLogin, 
          password: CustomJS.PasswordBetacomLogin, 
          secret: CustomJS.SecretBetacomLogin 
        };
        CustomJS.getRemoteData4(CustomJS.APIURLBetacomLogin, jsonObject, 'POST', {"Content-Type" : "application/json" }, retData2 => {
            CustomJS.getRemoteData4(CustomJS.APIURLCercaPeritoBetacom.replace('{0}', retData2.token).replace('{1}', numSinistro).replace('{2}', cognomeAssicurato), '', 'GET', 
              { "Authorization" : retData2.token }, retData3 => {
              if (Object.keys(retData3).length > 0) {
                if (retData3.nominativoPerito === 'Z Studio AllConsulting') {
                  document.getElementById('titoloInfoPerito').innerHTML = 'Perito in fase di assegnazione. Riprovare più avanti. Grazie';
                  document.getElementById('divFotoPerito').style.display = 'none';
                  document.getElementById('divNomePerito').style.visibility = 'hidden';
                  document.getElementById('divEmailPerito').style.visibility = 'hidden';
                  document.getElementById('divTelefPerito').style.visibility = 'hidden';
                }
                else {
                  document.querySelector('.e-lib.e-avatar.e-avatar-xlarge.image').style.backgroundImage = `url(${retData3.linkFotoPerito})`;
                  document.querySelector('.e-lib.e-avatar.e-avatar-xlarge.image').style.backgroundRepeat = 'no-repeat';
                  document.querySelector('.e-lib.e-avatar.e-avatar-xlarge.image').style.backgroundSize =  'cover';
                  document.querySelector('.e-lib.e-avatar.e-avatar-xlarge.image').style.backgroundPosition = 'center';
                  document.querySelector('.e-lib.e-avatar.e-avatar-xlarge.image').style.fontSize = '2.7vw';                  
                  document.getElementById('titoloInfoPerito').innerHTML = `Info Perito per il sinistro num. ${document.getElementById('NumeroSinistro').value.trim()}`;
                  document.getElementById('nomePerito').innerHTML = `Nominativo: <span style='color: purple;font-size: 1.4vw;'>${retData3.nominativoPerito}</span>`;
                  document.getElementById('indirizzoEmailPerito').innerHTML = `Indirizzo Email: <span style='color: purple;font-size: 1.4vw;'>${retData3.indirizzoEmailPerito}</span>`;
                  document.getElementById('telefonoPerito').innerHTML = `Telefono: <span style='color: purple;font-size: 1.4vw;'>${retData3.telefonoPerito}</span>`; 
                  //E' stato richiesto per adesso di non mettere mai le foto
                  //document.getElementById('divFotoPerito').style.removeProperty('display');
                  document.getElementById('divFotoPerito').style.display = 'none';
                  //----------------------------------------------------
                  document.getElementById('divNomePerito').style.visibility = 'normal';
                  document.getElementById('divEmailPerito').style.visibility = 'normal';
                  document.getElementById('divTelefPerito').style.visibility = 'normal';
                }
                setTimeout(() => this.setState({showDialog: true}), 100); 
              }
              else 
                  toast.warn('Sinistro non trovato oppure non ancora inserito a sistema, verificare i dati inseriti o riprovare tra 24h', {
                    //onClose: () => toast.dismiss(),       
                    containerId:'toastContainer1',                 
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
              this.toggleBlocking();   
            }, error => {
                let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
                toast.warn(messaggio, {
                    containerId: 'toastContainer1',
                    position: "bottom-right",
                    autoClose: 15000,
                    hideProgressBar: false,            
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    newestOnTop: true,
                    rtl: false,
                    pauseOnFocusLoss: true});
                this.toggleBlocking();
            }, true, false); 
        }, error => {
              let messaggio = _.isString(error) ? CustomJS.messaggioAPIBackendNonTrovata : CustomJS.messaggioNoBackend;
              toast.warn(messaggio, {
                  containerId: 'toastContainer1',
                  position: "bottom-right",
                  autoClose: 15000,
                  hideProgressBar: false,            
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  newestOnTop: true,
                  rtl: false,
                  pauseOnFocusLoss: true});
              this.toggleBlocking();
        }, true, false); 
    }
  }
  onValidateRecaptcha() {    
      document.getElementById('btnCercaPerito').disabled = false;
  } 
  onExpireRecaptcha() {
    document.getElementById('btnCercaPerito').disabled = true;
  } 
  componentDidMount() {
    document.getElementById('btnCercaPerito').disabled = true;
  }
}