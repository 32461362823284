import * as Loader from "react-loader-spinner";
import axios from 'axios';
const qs = require('qs');

export const APIUrlProd = 'https://sipa.allconsulting.org/new_sipa/api/allc.php';
export const tokenProd = 'zyAyefCyQZnwXVyPbv3TcI3hK3G5RvHve5oF3NcLIEAPdidd';
export const APIURLBetacomLogin = "https://bem-api.betacomservices.com/api/Authentication/login";
export const UserNameBetacomLogin = "cerca.perito";
export const PasswordBetacomLogin = "C3rC4P3r1t0!!";
export const SecretBetacomLogin = "FCxpxZM9ZCy98nQS2R49dI0igMZke9ROjUZocPrBda30m2UZlF4j_n17E32a3k6Sd8jUpWvZs_fPfNu_bKlDHbJVxoJVw4XOc7qpbF6VWVwyhTGkxJpCugon2YWMjTI7TOR81qDZHTf2M-d4vJ13GIInpLZpT1No4ZI-94TFCl6TdyAC3mUkmogzNMNPBHmsQJGXGCjpuZx7YRr2A5OVpFgg6ZUZ2O9kNZxBic8vPmcBi9ChQ_3EAuJOJWrkTqIoNyc7WP6Ek4qC-vd2Zyrc9UcAVN2Mi9RT767e8FjgHdw3PB-nLC0uCriWpg0IfR8hxDctc9Q4mL7aMsB3C8D-R6";
export const APIURLCercaPeritoBetacom = "https://bem-api.betacomservices.com/api/FindAppraiserApi/GetAppraiser?token={0}&numAccident={1}&insuredSurname={2}";
export const messaggioNoBackend = 'Attenzione!! Nessuna comunicazione attiva con il servizio accesso dati. Riprovare nuovamente l\'operazione e se il problema persiste, contattare il servizio di supporto Digital Praesidium (Gruppo All Consulting).'
export const messaggioAPIBackendNonTrovata = 'Attenzione!! Comunicazione attiva con il servizio accesso dati ma API non trovata oppure non autorizzata. Contattare il servizio di supporto Digital Praesidium (Gruppo All Consulting).';
export const loaderSpinners = [<Loader.Audio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.BallTriangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Bars width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Blocks width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Circles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.CirclesWithBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.ColorRing width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Comment width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
    <Loader.Discuss width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, //<Loader.Dna width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
    <Loader.FallingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.FidgetSpinner width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Grid width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.InfinitySpin width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.LineWave width={250} height={250} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.MagnifyingGlass width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
    <Loader.MutatingDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Oval width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
    <Loader.ProgressBar width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Puff width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Radio width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RevolvingDot width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Rings width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingLines width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.RotatingSquare width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.RotatingTriangles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.TailSpin width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.ThreeCircles width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, 
    <Loader.ThreeDots width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>, <Loader.Triangle width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>,
    <Loader.Vortex width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}/>, <Loader.Watch width={150} height={150} wrapperStyle={{ justifyContent: 'center' }} color='#133B95'/>];


export async function getRemoteData2(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {  //Chiamata Javascript puro XHR - Funziona bene
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    
    xhr.onerror = function (err) {
        debugger;
        cbFunctionKO(err);
    };

    xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4 && this.status === 200) {
            if (test) {
                cbFunctionOK(this.responseText);
        }
        else {
            let retData = JSON.parse(this.responseText);
            if (retData['response'].cod === 0)
                cbFunctionOK(retData['data']);
        }           
      }
      else if (this.readyState === 4 && this.status === 404)
        cbFunctionKO(this.responseText + this.statusText);
    });
    
    xhr.open(type, url);
    xhr.setRequestHeader("Content-Type", header);
    xhr.async = async; 
    
    if (!isNullEmpty(paramData))
        xhr.send(paramData);  
    else
        xhr.send();
}

export async function getRemoteData4(url, paramData, type, header, cbFunctionOK, cbFunctionKO, async, test) {  //Chiamata Axios - Funziona bene (speriamo)
    let config = {
        method: type,
        url: url,
        headers: header,
        data: JSON.stringify(paramData)
    };

    axios(config)
        .then(response => {
            debugger;
            if (test) {
                cbFunctionOK(response.data);
            }
            else {
                let retData = response.data;
                cbFunctionOK(retData);
            }
        })
        .catch(error => {
            cbFunctionKO(error);
        });
}

export function isNullEmpty(valore, multiArray) {
    try {
        if (multiArray) {
            var _wRet = false;
            for (var i = 0; i < valore.length; i++) {
                if (isNullEmpty(valore[i])) {
                    _wRet = true;
                    break;
                }
            }
            return _wRet;
        } else {
            switch (true) {
                case valore === null:
                    return true;
                    break;
                case Object.prototype.toString.call(valore) === '[object Date]':
                    return false;
                    break;
                case typeof valore === 'undefined':
                case typeof valore === 'null':
                    return true
                    break;
                case typeof valore === 'string':
                    if (valore.toString().trim() == "null" || valore.toString().trim() == "undefined") {
                        return true;
                    } else if (valore.toString().trim().length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
                case typeof valore === 'boolean':
                    return false;
                    break;
                case typeof valore === 'number':
                    return false;
                    break;
                case typeof valore === 'object':
                    if (Object.keys(valore).length > 0) {
                        return false;
                    } else {
                        return true;
                    }
                    break;
            }
        }
  
    }
    catch (ex) {
        return true;
    }
  }